import { formatUnixDate } from "core/model/utils/dates";
import {
  BarthelAssessment,
  EarlyRehabAssessment,
  SingerAssessment,
} from "core/types";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding } from "ds_legacy/materials/metrics";
import { Body, FONT_WEIGHT_BOLD } from "ds_legacy/materials/typography";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Locale } from "translations";
import Translations from "translations/types";

const StyledLink = styled(Body)`
  &:hover {
    text-decoration: underline;
  }
  font-weight: bold;
  cursor: pointer;
  margin: 0;
`;

export function StaticIndependenceScaleResult({
  goTo,
  linkLabel,
  locale,
  testId,
  title,
  translations,
  value,
}: {
  goTo?: string;
  linkLabel?: string;
  locale: Locale;
  testId:
    | "singer-assessment-result"
    | "early-rehab-assessment-result"
    | "barthel-assessment-result";
  title: string;
  translations: Translations;
  value?: SingerAssessment | BarthelAssessment | EarlyRehabAssessment;
}) {
  if (typeof value?.score !== "number" || !value.date) return null;

  return (
    <VerticalLayout data-testid={testId} padding={padding(0, 0, 1, 2)}>
      <Body as="h3" style={{ fontWeight: FONT_WEIGHT_BOLD }} margin={margin(0)}>
        {title}
      </Body>
      <VerticalLayout as="dl" boxSizing="border-box" width="100">
        <HorizontalLayout gap={margin(0.25)} data-testid={`${testId}-score`}>
          <Body as="dt" margin={margin(0)}>
            {
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreTitle
            }
          </Body>
          <Body as="dl" margin={margin(0)}>
            {value.score}
          </Body>
        </HorizontalLayout>
        <HorizontalLayout gap={margin(0.25)} data-testid={`${testId}-date`}>
          <Body as="dt" margin={margin(0)}>
            {
              translations.patient.medicalDiagnosis.singerPatientProfile
                .dateTitle
            }
          </Body>
          <Body as="dl" margin={margin(0)}>
            {formatUnixDate(value.date, locale)}
          </Body>
        </HorizontalLayout>
      </VerticalLayout>
      {value.form && goTo && linkLabel && (
        <StyledLink as={Link} to={goTo} primary>
          {linkLabel}
        </StyledLink>
      )}
    </VerticalLayout>
  );
}
