import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { EmptiableField } from "dsl/organisms/PatientProfileDetailsShared/shared";
import {
  grantLabelMaker,
  GrantValue,
  isGrantChecked,
} from "dsl/static/StaticGrantStatusField";
import { useTranslations } from "translations";

export function GrantStatusField({
  hasNotApplied,
  label,
  oldValue,
  testId,
  value,
  withDiff,
}: {
  hasNotApplied?: boolean;
  label: string;
  oldValue?: GrantValue | null | undefined;
  testId: string;
  value: GrantValue | null | undefined;
  withDiff?: boolean;
}) {
  const getOntology = useGetOntology();
  const isChecked = isGrantChecked(value);
  const translations = useTranslations();

  const newLabel = grantLabelMaker(
    label,
    value,
    getOntology,
    translations,
    hasNotApplied,
  );
  const oldLabel = grantLabelMaker(
    label,
    oldValue,
    getOntology,
    translations,
    hasNotApplied,
  );

  return isChecked || (withDiff && isGrantChecked(oldValue)) ? (
    <VerticalLayout margin={margin(0)}>
      <EmptiableField
        testId={testId}
        value={newLabel}
        oldValue={oldLabel}
        withDiff={withDiff}
      />
    </VerticalLayout>
  ) : null;
}
