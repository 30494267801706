import { IcdCode } from "core/types";
import { dp } from "ds_legacy/materials/metrics";
import { Subheading } from "ds_legacy/materials/typography";
import {
  BodyWrapper,
  StringField,
} from "dsl/organisms/PatientProfileDetailsShared/shared";
import Translations from "translations/types";

export type IcdValue = {
  additional_icd_codes: Array<IcdCode> | null | undefined;
  icd_code: IcdCode | null | undefined;
};

function getAdditionalIcdCodesList(icd_codes: Array<IcdCode>) {
  return icd_codes.map(
    (codeItem) => `(${codeItem.code}) ${codeItem.description} `,
  );
}

export function StaticIcdCodes({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  additional_icd_codes?: Array<IcdCode>;
  icd_code?: IcdCode;
  oldValue?: IcdValue | null | undefined;
  translations: Translations;
  value: IcdValue | null | undefined;
  withDiff?: boolean;
}) {
  return (
    <>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.icdCodes}
      </Subheading>
      <StringField
        testId="icd_code_description"
        value={
          value?.icd_code?.code &&
          value.icd_code.description &&
          `(${value.icd_code.code}) ${value.icd_code.description}`
        }
        oldValue={
          oldValue?.icd_code?.code &&
          oldValue.icd_code.description &&
          `(${oldValue.icd_code.code}) ${oldValue.icd_code.description}`
        }
        withDiff={withDiff}
        prefix={
          translations.patient.medicalDiagnosis.icdCodeMainPatientInfoLabel
        }
      />
      <StringField
        testId="additional_icd_codes"
        value={
          value?.additional_icd_codes &&
          getAdditionalIcdCodesList(value.additional_icd_codes).join("\n")
        }
        oldValue={
          oldValue?.additional_icd_codes &&
          getAdditionalIcdCodesList(oldValue.additional_icd_codes).join("\n")
        }
        withDiff={withDiff}
        multiLine
        wrap={(content) => (
          <BodyWrapper column>
            {`${translations.patient.medicalDiagnosis.icdCodesAdditionalPatientInfoLabel}${translations.general.colon} `}
            <div style={{ marginLeft: dp(-16) }}>{content}</div>
          </BodyWrapper>
        )}
      />
    </>
  );
}
