import {
  SEARCH_TYPE_CARE,
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_CARE_PROTECTED_AREA,
  SERVICE_CLOSED_AREA,
  SERVICE_COMPANIONSHIP,
  SERVICE_DEMENTIA,
  SERVICE_PALLIATIVE_CARE,
  SERVICE_PALLIATIVE_CERTIFICATE,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  SERVICE_TRACHEOSTOMY,
  SOLUTION_SHORT_TERM_STATIC_CARE,
} from "core/consts";
import { isRehabSearch } from "core/model/auctions";
import { formatUnixDate } from "core/model/utils/dates";
import { capitaliseFirst } from "core/model/utils/strings";
import { GetOntologyType } from "core/types";
import { isValid } from "date-fns";
import { getOrderedItems } from "ds_legacy/components/ChipGroup";
import { solutionSortOrder } from "dsl/ecosystems/PatientCreatePage/PatientCreateForm";
import {
  ArrayChip,
  BooleanChip,
  NumberChip,
  OntologyChip,
  StringChip,
} from "dsl/organisms/PatientProfileDetailsShared/shared";
import { Locale as LocaleString } from "translations";
import Translations from "translations/types";
import { FieldsHolderType } from "../organisms/PatientMenu/transform";

const shouldDisplayDuration = (duration: number, solutions: Array<ToType>) =>
  solutions &&
  solutions.length === 1 &&
  solutions[0] === SOLUTION_SHORT_TERM_STATIC_CARE
    ? duration <= 60
    : true;

const getAgeInterval = (age_interval: ToType) =>
  age_interval?.value || age_interval?.name || age_interval || "";

const getBirthDateRange = (birthDate: string, ageRange: string) =>
  `${birthDate}${
    ageRange && typeof ageRange === "string" ? ` (${ageRange})` : ""
  }`;

type ChipsValue = {
  fields: FieldsHolderType | null | undefined;
  services: Array<number>;
};

function computeCarestartFlexibility(
  fields: FieldsHolderType | null | undefined,
  locale: LocaleString,
  translations: Translations,
): string | null {
  const careStartDate = fields?.start_date;
  const flexible = fields?.start_date_flexible
    ? ` ${translations.patient.flexible}`
    : "";

  if (!careStartDate) return null;
  return formatUnixDate(careStartDate, locale).concat(flexible);
}

export function StaticPatientProfileTranferChips({
  disabled = false,
  encryptionAvailable,
  forClinic,
  getOntology,
  locale,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  disabled?: boolean;
  encryptionAvailable: boolean;
  forClinic?: boolean;
  getOntology: GetOntologyType;
  locale: LocaleString;
  oldValue?: ChipsValue;
  translations: Translations;
  value: ChipsValue;
  withDiff?: boolean;
}) {
  const { fields, services } = value || ({} as ChipsValue);
  const { fields: oldFields, services: oldServices } =
    oldValue || ({} as ChipsValue);

  const isElective = fields?.isElective;
  return (
    <>
      <OntologyChip
        getOntology={getOntology}
        type="patientType"
        value={forClinic && fields?.search_type}
        oldValue={forClinic && oldFields?.search_type}
        keyPrefix="patientType"
        selected
        bold
        disabled={disabled}
      />
      <ArrayChip
        getOntology={getOntology}
        type="solution"
        value={
          fields?.solutions &&
          getOrderedItems(solutionSortOrder, fields.solutions)
        }
        oldValue={
          oldFields?.solutions &&
          getOrderedItems(solutionSortOrder, oldFields?.solutions)
        }
        withDiff={withDiff}
        keyPrefix="solution"
        primary
        bold
        disabled={disabled}
      />
      <ArrayChip
        getOntology={getOntology}
        type="specializations"
        value={fields?.specializations}
        oldValue={oldFields?.specializations}
        withDiff={withDiff}
        keyPrefix="specializations"
        primary
        bold
        disabled={disabled}
      />
      {isElective && (
        <StringChip
          value={translations.careproviderApp.showElectivePatient}
          keyPrefix="elective"
          selected
          bold
          textBreak
        />
      )}
      <StringChip
        value={computeCarestartFlexibility(fields, locale, translations)}
        oldValue={computeCarestartFlexibility(oldFields, locale, translations)}
        withDiff={withDiff}
        prefix={translations.patient.expectedStartDate}
        keyPrefix="start_date"
        primary
        bold
        disabled={disabled}
      />
      {isRehabSearch(fields?.search_type) &&
        (isValid(value.fields?.discharge_date) ||
          isValid(oldValue?.fields?.discharge_date)) && (
          <StringChip
            value={
              isValid(value.fields?.discharge_date)
                ? formatUnixDate(value.fields?.discharge_date, locale)
                : null
            }
            oldValue={
              isValid(oldValue?.fields?.discharge_date)
                ? formatUnixDate(oldValue?.fields?.discharge_date, locale)
                : null
            }
            withDiff={withDiff}
            prefix={translations.patient.expectedDischargeDate}
            keyPrefix="discharge_date"
            primary
            bold
            disabled={disabled}
          />
        )}
      <BooleanChip
        value={!!(fields?.direct_transfer_necessary != null)}
        oldValue={!!(oldFields?.direct_transfer_necessary != null)}
        withDiff={withDiff}
        label={translations.patient.directTransfer}
        keyPrefix="direct_transfer_necessary"
        primary
        bold
        disabled={disabled}
      />
      {fields?.search_type === SEARCH_TYPE_CARE && (
        <>
          <NumberChip
            value={
              ((forClinic ||
                shouldDisplayDuration(
                  fields.care_duration_in_days,
                  fields.solutions,
                )) &&
                fields.care_duration_in_days) ||
              null
            }
            oldValue={
              (oldFields &&
                (forClinic ||
                  shouldDisplayDuration(
                    oldFields.care_duration_in_days,
                    oldFields.solutions,
                  )) &&
                oldFields.care_duration_in_days) ||
              null
            }
            withDiff={withDiff}
            transform={(value) =>
              getOntology({ type: "careDurations", key: value })
            }
            prefix={translations.patient.careDuration}
            keyPrefix="care_duration_in_days"
            primary
            bold
            disabled={disabled}
          />
          <BooleanChip
            value={fields?.interested_long_term_stay}
            oldValue={oldFields?.interested_long_term_stay}
            withDiff={withDiff}
            label={capitaliseFirst(
              translations.patient.interestedStayLongTermRequest,
            )}
            keyPrefix="long_term_stay"
            primary
            bold
            disabled={disabled}
          />
        </>
      )}
      {encryptionAvailable ? (
        <StringChip
          value={
            fields?.birth_date
              ? getBirthDateRange(
                  fields.birth_date,
                  getAgeInterval(fields?.age_interval),
                )
              : null
          }
          prefix={translations.patient.dateOfBirth}
          keyPrefix="birth_date"
          primary
          bold
          disabled={disabled}
        />
      ) : (
        <StringChip
          value={getAgeInterval(fields?.age_interval)}
          oldValue={getAgeInterval(oldFields?.age_interval)}
          withDiff={withDiff}
          prefix={translations.patient.age}
          keyPrefix="age"
          primary
          bold
          disabled={disabled}
        />
      )}
      <StringChip
        value={fields?.zipcode || ""}
        oldValue={oldFields?.zipcode || ""}
        withDiff={withDiff}
        prefix={translations.address.zipcode}
        keyPrefix="zipcode"
        primary
        bold
        disabled={disabled}
      />
      <StringChip
        value={forClinic ? fields?.radius_in_km : null}
        oldValue={forClinic ? oldFields?.radius_in_km : null}
        withDiff={withDiff}
        prefix={translations.patient.searchRadius}
        suffix="km"
        keyPrefix="radius_in_km"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_CLOSED_AREA)}
        oldValue={oldServices?.includes(SERVICE_CLOSED_AREA)}
        withDiff={withDiff}
        label={translations.patient.closedAreaNeeded}
        keyPrefix="closed_area"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_CARE_PROTECTED_AREA)}
        oldValue={oldServices?.includes(SERVICE_CARE_PROTECTED_AREA)}
        withDiff={withDiff}
        label={translations.patient.mentalState.protectedAreaNeeded.label}
        keyPrefix="closed_area"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_PALLIATIVE_CARE)}
        oldValue={oldServices?.includes(SERVICE_PALLIATIVE_CARE)}
        withDiff={withDiff}
        label={translations.patient.palliativeCare.label}
        keyPrefix="palliative_care"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_PALLIATIVE_CERTIFICATE)}
        oldValue={oldServices?.includes(SERVICE_PALLIATIVE_CERTIFICATE)}
        withDiff={withDiff}
        label={
          translations.patient.medicalDiagnosis.palliativeCertificateNeeded
        }
        keyPrefix="palliative_certificate"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_TRACHEOSTOMY)}
        oldValue={oldServices?.includes(SERVICE_TRACHEOSTOMY)}
        withDiff={withDiff}
        label={translations.patient.careNeeds.tracheostomy.label}
        keyPrefix="tracheostomy"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_SPECIALISED_INTENSIVE_NURSE)}
        oldValue={oldServices?.includes(SERVICE_SPECIALISED_INTENSIVE_NURSE)}
        withDiff={withDiff}
        label={translations.ontologies.service.values.specialisedIntensiveNurse}
        keyPrefix="specialised_intensive_nurse"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_PSYCHIATRIC_CARE)}
        oldValue={oldServices?.includes(SERVICE_PSYCHIATRIC_CARE)}
        withDiff={withDiff}
        label={translations.patient.careNeeds.psychiatric_care.label}
        keyPrefix="psychiatric_care"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_BASIC_MEDICAL_CARE)}
        oldValue={oldServices?.includes(SERVICE_BASIC_MEDICAL_CARE)}
        withDiff={withDiff}
        label={translations.ontologies.service.values.basicMedicalCare}
        keyPrefix="basic_medical_care"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_BASIC_CARE)}
        oldValue={oldServices?.includes(SERVICE_BASIC_CARE)}
        withDiff={withDiff}
        label={translations.patient.careNeeds.baseCare}
        keyPrefix="basic_care"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_COMPANIONSHIP)}
        oldValue={oldServices?.includes(SERVICE_COMPANIONSHIP)}
        withDiff={withDiff}
        label={translations.patient.careNeeds.companionship}
        keyPrefix="companionship"
        primary
        bold
        disabled={disabled}
      />
      <BooleanChip
        value={services?.includes(SERVICE_DEMENTIA)}
        oldValue={oldServices?.includes(SERVICE_DEMENTIA)}
        withDiff={withDiff}
        label={translations.patient.dementia}
        keyPrefix="dementia"
        primary
        bold
        disabled={disabled}
      />
    </>
  );
}
