import { SHORT_TERM_CARE_GRANT_NONE } from "core/consts";
import { GetOntologyType } from "core/types";
import { Body } from "ds_legacy/materials/typography";
import Translations from "translations/types";

export const isShortTermGrantChecked = (
  value: number | null | undefined,
): boolean => (value && value > 0) || false;

export function StaticShortTermCareGrant({
  getOntology,

  testId,
  translations,
  value,
}: {
  getOntology: GetOntologyType;
  testId: string;
  translations: Translations;
  value: number | null | undefined;
}) {
  const isChecked = isShortTermGrantChecked(value);
  const hasValue = value && value !== SHORT_TERM_CARE_GRANT_NONE;

  const title = translations.patient.shortTermCareGrant;

  if (!isChecked) return null;
  return (
    <Body data-testid={testId}>
      {title}
      {hasValue &&
        `${translations.general.colon} ${getOntology({
          type: "shortTermGrant",
          key: value,
        })}`}
    </Body>
  );
}
