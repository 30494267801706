import { GRANT_STATUS_NOT_APPLIED } from "core/consts";
import { GetOntologyType } from "core/types";
import { Body } from "ds_legacy/materials/typography";
import Translations from "translations/types";

export type GrantValue = boolean | number | string;

export const isGrantChecked = (value: GrantValue | null | undefined): boolean =>
  (typeof value === "number" && value > 0) ||
  (typeof value === "string" && value.length > 0) ||
  value === true;

export const grantLabelMaker = (
  label: string,
  value: GrantValue | null | undefined,
  getOntology: GetOntologyType,
  translations: Translations,
  hasNotApplied?: boolean,
) => {
  if (!value) return null;
  switch (typeof value) {
    case "string":
      break;
    case "boolean":
      value = "";
      break;
    case "number":
      if (value == GRANT_STATUS_NOT_APPLIED && !hasNotApplied) {
        value = "";
      } else {
        value = `${translations.general.colon} ${getOntology({
          type: "grantStatus",
          key: Number(value),
        })}`;
      }
      break;
    default:
  }

  return `${label}${value}`;
};

export function StaticGrantStatusField({
  getOntology,
  hasNotApplied,
  label,
  testId,
  translations,
  value,
}: {
  getOntology: GetOntologyType;
  hasNotApplied?: boolean;
  label: string;
  testId: string;
  translations: Translations;
  value: GrantValue | null | undefined;
}) {
  const isChecked = isGrantChecked(value);

  const newLabel = grantLabelMaker(
    label,
    value,
    getOntology,
    translations,
    hasNotApplied,
  );

  if (!isChecked) return null;
  return <Body data-testid={testId}>{newLabel}</Body>;
}
