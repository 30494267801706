import { formatDistance } from "core/model/utils/location";
import { hasMobile } from "core/model/utils/services";
import { Auction, GetOntologyType } from "core/types";
import { Banner } from "ds/ui";
import { Divider, HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import { Subheading } from "ds_legacy/materials/typography";
import {
  FieldsHolderType,
  extractPatientFields,
  fieldsHolder,
} from "dsl/organisms/PatientMenu/transform";
import cloneDeep from "lodash/cloneDeep";
import styled from "styled-components";
import { Locale as LocaleString } from "translations";
import Translations from "translations/types";
import { StaticPatientProfileTranferChips } from "./StaticPatientProfileTranferChips";

const OverviewCardChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${dp(-4)};
`;

function extractTransferFields(auction: Auction | undefined): FieldsHolderType {
  const fields = { ...fieldsHolder };
  fields.solutions = auction?.solutions;
  fields.specializations = auction?.specializations;
  return fields;
}

function getModAuction(
  auction: Auction | null | undefined,
  solutions: number[],
) {
  const modAuction = cloneDeep(auction);
  if (modAuction) modAuction.solutions = solutions;
  return (modAuction || { patient: {} }) as Auction;
}

function generateChipsValue({
  auction,
  fields,
  noServices,
}: {
  auction: Auction | null | undefined;
  fields: FieldsHolderType | null | undefined;
  noServices?: boolean;
}) {
  return {
    fields,
    services: noServices ? [] : auction?.services || [],
  };
}

function formatDistanceForZipCodeMessage(
  distance: number | null | undefined,
  translations: Translations,
): string {
  if (!distance || distance <= 0) {
    return "";
  }
  return distance > 0
    ? translations.patient.distanceFromYou({
        distance: formatDistance(distance),
      })
    : "";
}

export function StaticChipInfo({
  auction,
  distance = 0,
  encryptionAvailable,
  getOntology,
  locale,
  oldAuction,
  requestAvailable = true,
  requestSolutions,
  translations,
}: {
  auction: Auction | undefined;
  distance?: number | null;
  encryptionAvailable: boolean;
  getOntology: GetOntologyType;
  locale: LocaleString;
  oldAuction: Auction | null | undefined;
  requestAvailable?: boolean | null;
  requestSolutions: number[];
  translations: Translations;
}) {
  const withDiff = oldAuction != null;

  const modAuction = getModAuction(auction, requestSolutions);
  const modOldAuction = getModAuction(oldAuction, requestSolutions);
  const distanceForZipCode =
    requestSolutions?.length && hasMobile(requestSolutions)
      ? formatDistanceForZipCodeMessage(distance, translations)
      : "";

  return (
    <>
      <HorizontalLayout
        flexWrap="wrap"
        margin={margin(0, 0, 0, 0)}
        padding={padding(1, 5)}
      >
        <div
          data-testid="overview-card-chip-info"
          style={{ minWidth: "50%", margin: margin(0, 2, 0, 0) }}
        >
          <HorizontalLayout aligned>
            <Subheading margin={margin(0, 0, 0, 0)} bold>
              {translations.auctionRequest.typeOfTransfer}
            </Subheading>
          </HorizontalLayout>

          <HorizontalLayout flexWrap="wrap">
            <OverviewCardChipsContainer>
              <StaticPatientProfileTranferChips
                disabled={!requestAvailable}
                encryptionAvailable={encryptionAvailable}
                getOntology={getOntology}
                locale={locale}
                oldValue={generateChipsValue({
                  fields: extractTransferFields(modOldAuction),
                  auction: modOldAuction,
                  noServices: true,
                })}
                translations={translations}
                value={generateChipsValue({
                  fields: extractTransferFields(modAuction),
                  auction: modAuction,
                  noServices: true,
                })}
                withDiff={withDiff}
              />
            </OverviewCardChipsContainer>
          </HorizontalLayout>
        </div>
      </HorizontalLayout>
      <Divider />
      <HorizontalLayout flexWrap="wrap" padding={padding(1, 5)}>
        <Subheading fullWidth margin={margin(0, 0, 0, 0)} bold>
          {translations.auctionRequest.mostImportantTransferDetails}
        </Subheading>
        {auction?.country_wide_search && (
          <div className="my-2 w-full">
            <Banner
              message={translations.auctionRequest.germanyWideSearchWarning}
              color="primary"
            />
          </div>
        )}

        <OverviewCardChipsContainer>
          <StaticPatientProfileTranferChips
            disabled={!requestAvailable}
            encryptionAvailable={encryptionAvailable}
            getOntology={getOntology}
            locale={locale}
            oldValue={generateChipsValue({
              fields: extractPatientFields(
                modOldAuction,
                ["solutions", "specializations"],
                { distanceForZipCode },
              ),
              auction: modOldAuction,
            })}
            translations={translations}
            value={generateChipsValue({
              fields: extractPatientFields(
                modAuction,
                ["solutions", "specializations"],
                { distanceForZipCode },
              ),
              auction: modAuction,
            })}
            withDiff={withDiff}
          />
        </OverviewCardChipsContainer>
      </HorizontalLayout>
    </>
  );
}
